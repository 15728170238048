import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { v4 } from "uuid";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../../components/my-modal/my-modal";
import Navbar from "../../../components/navbar/navbar";
import { ItineraryContext } from "../../../contexts/itinerary.context";
import { Routes } from "../../../routes";
import { TAG_IMAGES_FOLDER } from "../../../variables";
import styles from "./waypoint-anecdote.module.css";
import { playCircle } from "ionicons/icons";
import axios from "axios";
import { pauseCircle } from "ionicons/icons";

const WaypointAnecdotePage = () => {
  const { t } = useTranslation();
  const itineraryContext = useContext(ItineraryContext);
  const [characterImage, setCharacterImage] = useState<string | undefined>("");
  const [anecdote, setAnecdote] = useState<string | undefined>("");
  const [anecdoteHint, setAnecdoteHint] = useState<string | undefined>("");
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const [myKey, setMyKey] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_ANECDOTE)) return;
    // setMyIndex(itineraryContext.gameIndex);
    setMyKey(v4());
    setAnecdote(itineraryContext.currentWaypoint?.locales?.[0]?.anecdote);
    setAnecdoteHint(
      itineraryContext.currentWaypoint?.locales?.[0]?.anecdoteHint
    );
  }, [location]);

  const [audioURL, setAudioURL] = useState("");
  const [detailsAudioURL, setDetailsAudioURL] = useState("");
  const [isPlayingDescription, setIsPlayingDescription] = useState(false);
  const [isPlayingDetails, setIsPlayingDetails] = useState(false);
  const audioRefDescription = useRef<HTMLAudioElement>(null);
  const audioRefDetails = useRef<HTMLAudioElement>(null);
  const locale =
    localStorage.getItem("locale") !== null
      ? localStorage.getItem("locale")
      : "it";

  useEffect(() => {
    const getAudioURL = async () => {
      try {
        console.log("SEARCH_AUDIO_ANECDOTE", itineraryContext.currentWaypoint?.id, locale);
        const checkUrl = `https://zonzo.fra1.digitaloceanspaces.com/media/waypoints/${itineraryContext.currentWaypoint?.id}/anecdote_${locale}.mp3`;
        await axios.get(checkUrl);
        setAudioURL(checkUrl);
      } catch (e) {
        setAudioURL("");
        console.error("AUDIO_NOT_FOUND");
      }
    };
    const getDetailsAudioURL = async () => {
      try {
        const checkUrl = `https://zonzo.fra1.digitaloceanspaces.com/media/waypoints/${itineraryContext.currentWaypoint?.id}/details_${locale}.mp3`;
        await axios.get(checkUrl);
        setDetailsAudioURL(checkUrl);
      } catch (e) {
        setDetailsAudioURL("");
        console.error("AUDIO_NOT_FOUND");
      }
    };
    const getImageURL = async () => {
      try {
        const checkUrl = `https://zonzo.fra1.digitaloceanspaces.com/media/waypoints/${itineraryContext.currentWaypoint?.id}/character.png`;
        await axios.get(checkUrl);
        setCharacterImage(checkUrl);
      } catch (e) {
        setCharacterImage("");
        console.error("IMAGE_NOT_FOUND");
      }
    };

    getAudioURL();
    getDetailsAudioURL();
    getImageURL();
  }, [itineraryContext.currentWaypoint?.id, locale]);

  const togglePlayDescription = () => {
    if (audioRefDescription.current) {
      audioRefDetails.current?.pause();
      setIsPlayingDetails(false);
      if (isPlayingDescription) {
        audioRefDescription.current.pause();
      } else {
        audioRefDescription.current.play();
      }
      setIsPlayingDescription(!isPlayingDescription);
    }
  };

  const togglePlayDetails = () => {
    if (audioRefDetails.current) {
      audioRefDescription.current?.pause();
      setIsPlayingDescription(false);
      if (isPlayingDetails) {
        audioRefDetails.current.pause();
      } else {
        audioRefDetails.current.play();
      }
      setIsPlayingDetails(!isPlayingDetails);
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} backButtonDisabled={true} />
      </IonHeader>
      <IonContent color="secondary">
        <div className={styles.waypointAnecdoteContainer}>
          {/* <div>
            <LazyLoadImage
              className={styles.tagImage}
              src={`${TAG_IMAGES_FOLDER}/${itineraryContext.currentItinerary?.tags[0].logoName}`}
              alt="Itinerary tag"
              placeholder={<IonSpinner />}
            ></LazyLoadImage>
          </div> */}
          {characterImage && (
            <LazyLoadImage
              className={styles.characterImage}
              src={characterImage}
              alt="Character"
              placeholder={<IonSpinner />}
            ></LazyLoadImage>
          )}
          {!characterImage && (
            <LazyLoadImage
              className={styles.balloonImage}
              src="assets/itinerary/balloon.svg"
              alt="Balloon"
              placeholder={<IonSpinner />}
            ></LazyLoadImage>
          )}
          <p
            key={myKey}
            // key={`${itineraryContext.currentItinerary?.id}-${itineraryContext.currentWaypoint?.id}`}
            className={`zonzo-scrollbar-primary ${styles.anecdoteText} ${
              isPlatform("android") ? styles.anecdoteTextAndroid : ""
            } zonzo-scrollbar ion-text-start`}
          >
            {anecdote}
          </p>
          <div className={styles.moreContainer}>
            <IonButton
              onClick={() => setReadMoreOpen(true)}
              className={`${styles.readMoreButton} ion-text-uppercase`}
              fill="clear"
            >
              {t("ui.readMore")}
            </IonButton>
            {audioURL && (
              <div className={styles.audioControl}>
                <audio
                  ref={audioRefDescription}
                  src={audioURL}
                  onEnded={() => setIsPlayingDescription(false)}
                />
                <IonIcon
                  icon={isPlayingDescription ? pauseCircle : playCircle}
                  onClick={togglePlayDescription}
                  className={styles.audioButton}
                />
              </div>
            )}
          </div>
          <MyButtonWithBorder
            onClick={() => itineraryContext.continue()}
            size="large"
            color="secondary"
            className={`${styles.continueButton} ion-text-uppercase`}
          >
            {t("ui.continue")}
          </MyButtonWithBorder>
        </div>
        <MyModal
          containerClassName={styles.readMoreModal}
          isOpen={readMoreOpen}
          onCloseClick={() => {
            setReadMoreOpen(false);
          }}
          onBackgroundClick={() => {
            setReadMoreOpen(false);
          }}
        >
          <p className={`${styles.readMoreParagraph} zonzo-scrollbar-primary`}>
            {anecdoteHint}
          </p>
            {detailsAudioURL && (
              <div className={styles.audioControlMoreDetails}>
              <audio
                ref={audioRefDetails}
                src={detailsAudioURL}
                onEnded={() => setIsPlayingDetails(false)}
              />
              <IonIcon
                icon={isPlayingDetails ? pauseCircle : playCircle}
                onClick={togglePlayDetails}
                className={styles.audioButton}
                />
              </div>
            )}
        </MyModal>
      </IonContent>
    </IonPage>
  );
};

export default WaypointAnecdotePage;
