import { IonContent, IonHeader, IonPage, IonSpinner } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { v4 } from "uuid";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import Navbar from "../../../components/navbar/navbar";
import { ItineraryContext } from "../../../contexts/itinerary.context";
import { Routes } from "../../../routes";
import { TAG_IMAGES_FOLDER } from "../../../variables";
import styles from "./itinerary-recap.module.css";

const ItineraryRecapPage = () => {
  const { t } = useTranslation();
  const itineraryContext = useContext(ItineraryContext);
  const [myKey, setMyKey] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_RECAP)) return;
    setMyKey(v4());
  }, [location]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} backButtonDisabled={true} />
      </IonHeader>
      <IonContent color="secondary">
        <div
          // key={`${itineraryContext.currentItinerary?.id}`}
          className={styles.itineraryRecapContainer}
        >
          <div>
            {/* <LazyLoadImage
              className={styles.tagImage}
              src={`${TAG_IMAGES_FOLDER}/${itineraryContext.currentItinerary?.tags[0].logoName}`}
              alt="Itinerary tag"
              placeholder={<IonSpinner />}
            ></LazyLoadImage> */}
            <p
              className={`${styles.upperDescription} ion-text-uppercase ion-text-center`}
            >
              {t("itinerary.recap")}
            </p>
          </div>
          {/* <IonImg src='assets/itinerary/balloon.svg' alt='Balloon'></IonImg> */}
          <p
            key={myKey}
            className={`${styles.recapText} ion-text-start zonzo-scrollbar-primary`}
          >
            {itineraryContext.currentItinerary?.locales?.[0]?.recap}
          </p>
          <MyButtonWithBorder
            onClick={() => itineraryContext.continue()}
            size="large"
            color="secondary"
            className={`${styles.continueButton} ion-text-uppercase`}
          >
            {t("ui.continue")}
          </MyButtonWithBorder>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ItineraryRecapPage;
