import { IonRouterOutlet } from "@ionic/react";
import { logOutOutline } from "ionicons/icons";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, useHistory } from "react-router-dom";
import Menu from "./components/menu/menu";
import Tabs from "./components/tabs/tabs";
import { UserContext } from "./contexts/user.context";
import AccessPage from "./pages/access/access-page";
import DeleteAccountPage from "./pages/access/deleteAccount/delete-account-page";
// import LoginPage from "./pages/access/login/login-page";
import RecoverPasswordPage from "./pages/access/recoverPassword/recover-password-page";
import SignupPage from "./pages/access/signup/signup-page";
import ChooseItineraryPage from "./pages/choose-itinerary/choose-itinerary";
import CitySelectionPage from "./pages/city-selection/city-selection";
import CityItinerariesPage from "./pages/city-itineraries/city-itineraries";
import IntroPage from "./pages/intro/intro-page";
import ItinerarySelectionPage from "./pages/itinerary-selection/itinerary-selection";
import ItineraryCompletePage from "./pages/itinerary/itinerary-complete/itinerary-complete";
import ItineraryRecapPage from "./pages/itinerary/itinerary-recap/itinerary-recap";
import WaypointAnecdotePage from "./pages/itinerary/waypoint-anecdote/waypoint-anecdote";
import WaypointGameMatchPage from "./pages/itinerary/waypoint-game-match/waypoint-game-match";
import WaypointGamePage from "./pages/itinerary/waypoint-game/waypoint-game";
import WaypointIntroPage from "./pages/itinerary/waypoint-intro/waypoint-intro";
import WaypointRiddlePage from "./pages/itinerary/waypoint-riddle/waypoint-riddle";
import LanguageSelectionPage from "./pages/language-selection/language-selection-page";
import LeaderboardPage from "./pages/leaderboard/leaderboard-page";
import PaymentPage from "./pages/payment/payment-page";
import QrCodeScannerPage from "./pages/qr-code-scanner/qr-code-scanner-page";
import SelectedItineraryIntroPage from "./pages/selected-itinerary-intro/selected-itinerary-intro-page";
import SelectedTagIntroPage from "./pages/selected-tag-intro/selected-tag-intro-page";
import TagSelectionPage from "./pages/tag-selection/tag-selection-page";
import TutorialPage from "./pages/tutorial/tutorial-page";
import UserItinerariesPage from "./pages/user-itineraries/user-itineraries-page";
import WalletPage from "./pages/wallet/wallet-page";
import { Routes } from "./routes";
// import NFTsPage from "./pages/wallet/nft-page";

const StoreContent = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Menu
        topButtons={[]}
        bottomButtons={[
          {
            label: t("privacy.terms"),
            link: "https://zonzoapp.com/termini-e-condizioni/"
          },
          {
            label: t("privacy.policy"),
            link: "https://zonzoapp.com/privacy-policy/"
          }, {
            label: t("access.changelanguage"),
            underlined: false,
            onButtonClick: async () => {
              localStorage.removeItem("locale");
              history.push(Routes.LANGUAGE_SELECTION);
            },
          },
          {
            label: t("access.logout"),
            underlined: false,
            icon: logOutOutline,
            onButtonClick: async () => {
              await userContext.logout();
            },
          },
          {
            underlined: false,
            label: t("privacy.deleteAccount"),
            onButtonClick: () => {
              history.push(Routes.DELETE_ACCOUNT);
            },
          },
        ]}
      ></Menu>
      <Tabs>
        <IonRouterOutlet id="main-content">
          <Route exact path={Routes.LANGUAGE_SELECTION}>
            <LanguageSelectionPage />
          </Route>
          <Route exact path={Routes.INTRO}>
            <IntroPage />
          </Route>
          <Route exact path={Routes.TUTORIAL}>
            <TutorialPage />
          </Route>
          <Route exact path={Routes.ACCESS}>
            <AccessPage />
          </Route>
          {/* <AuthRoute shouldBeAuthed={false} redirectTo={Routes.CITY_SELECTION} exact path={Routes.LOGIN} component={LoginPage} /> */}
          {/* <Route exact path={Routes.LOGIN}>
            <LoginPage />
          </Route> */}
          <Route exact path={Routes.RECOVER_PASSWORD}>
            <RecoverPasswordPage />
          </Route>
          {/* <AuthRoute shouldBeAuthed={false} redirectTo={Routes.CITY_SELECTION} exact path={Routes.LOGIN} component={LoginPage} /> */}

          <Route exact path={Routes.SIGNUP}>
            <SignupPage />
          </Route>
          <Route exact path={Routes.DELETE_ACCOUNT}>
            <DeleteAccountPage />
          </Route>
          {/* GUARDS NEEDED FOR ROUTES BELOW */}
          {/* ITINERARY PURCHASE ROUTES */}
          <Route exact path={Routes.CHOOSE_ITINERARY}>
            <ChooseItineraryPage />
          </Route>
          <Route exact path={Routes.CITY_SELECTION}>
            <CitySelectionPage />
          </Route>
          {/* <AuthRoute shouldBeAuthed={true} redirectTo={Routes.LOGIN} exact path={Routes.CITY_SELECTION} component={CitySelectionPage} /> */}
          <Route exact path={Routes.TAG_SELECTION}>
            <TagSelectionPage />
          </Route>
          <Route exact path={Routes.CITY_ITINERARIES}>
            <CityItinerariesPage />
          </Route>
          <Route exact path={Routes.SELECTED_TAG_INTRO}>
            <SelectedTagIntroPage />
          </Route>
          <Route exact path={Routes.ITINERARY_SELECTION}>
            <ItinerarySelectionPage />
          </Route>
          <Route exact path={Routes.SELECTED_ITINERARY_INTRO}>
            <SelectedItineraryIntroPage />
          </Route>
          <Route exact path={Routes.PAYMENT}>
            <PaymentPage />
          </Route>
          {/* GAME ROUTES */}
          <Route exact path={Routes.WAYPOINT_INTRO}>
            <WaypointIntroPage />
          </Route>
          <Route path={`${Routes.WAYPOINT_RIDDLE}/:gameIndex`}>
            <WaypointRiddlePage />
          </Route>
          <Route path={`${Routes.WAYPOINT_GAME}/:gameIndex`}>
            <WaypointGamePage />
          </Route>
          <Route path={`${Routes.WAYPOINT_GAME_MATCH}/:gameIndex`}>
            <WaypointGameMatchPage />
          </Route>
          <Route exact path={Routes.WAYPOINT_ANECDOTE}>
            <WaypointAnecdotePage />
          </Route>
          <Route exact path={Routes.WAYPOINT_RECAP}>
            <ItineraryRecapPage />
          </Route>
          <Route exact path={Routes.ITINERARY_COMPLETE}>
            <ItineraryCompletePage />
          </Route>
          {/* OTHER ROUTES */}
          <Route exact path={Routes.USER_ITINERARIES}>
            <UserItinerariesPage />
          </Route>
          <Route exact path={Routes.WALLET}>
            <WalletPage />
          </Route>
          {/* <Route exact path={Routes.NFTS}>
            <NFTsPage />
          </Route> */}
          <Route exact path={Routes.QR_CODE_SCANNER}>
            <QrCodeScannerPage />
          </Route>
          <Route exact path={Routes.LEADERBOARD}>
            <LeaderboardPage />
          </Route>
          <Route exact path="/">
            <Redirect to={Routes.LANGUAGE_SELECTION} />
          </Route>
        </IonRouterOutlet>
      </Tabs>
    </>
  );
};
export default StoreContent;
