import { IonSpinner, isPlatform, IonIcon } from "@ionic/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./game-intro.module.css";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { playCircle, pauseCircle } from "ionicons/icons";

interface GameIntroProps {
  upperImageUrl: string;
  gameId: number | undefined;
  gameImage: {
    url: string;
    alt: string;
  };
  gameName: string;
  gameDescription: string;
}

const GameIntro = (props: GameIntroProps) => {
  const [audioURL, setAudioURL] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const locale =
    localStorage.getItem("locale") !== null
      ? localStorage.getItem("locale")
      : "it";

  useEffect(() => {
    const getAudioURL = async () => {
      try {
        const checkUrl = `https://zonzo.fra1.digitaloceanspaces.com/media/games/${props.gameId}/description_${locale}.mp3`;
        await axios.get(checkUrl);
        setAudioURL(checkUrl);
      } catch (e) {
        console.error("AUDIO_NOT_FOUND");
      }
    };

    getAudioURL();
  }, [props.gameId, locale]);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        {/* <LazyLoadImage
          className={styles.upperImage}
          src={props.upperImageUrl}
          alt="Game intro upper image"
          placeholder={<IonSpinner className="centered" />}
        ></LazyLoadImage> */}
        <p className={`${styles.upperText} ion-text-uppercase ion-text-center`}>
          {props.gameName}
        </p>
      </div>
      <LazyLoadImage
        src={props.gameImage.url}
        className={styles.gameImage}
        alt={props.gameImage.alt}
        placeholder={<IonSpinner />}
      ></LazyLoadImage>
      <p
        className={`zonzo-scrollbar-primary ${styles.description} ${
          isPlatform("android") ? styles.descriptionAndroid : ""
        } ion-text-start`}
        dangerouslySetInnerHTML={{ __html: props.gameDescription ?? "" }}
      ></p>
      {audioURL && (
        <div className={styles.audioControl}>
          <audio
            ref={audioRef}
            src={audioURL}
            onEnded={() => setIsPlaying(false)}
          />
          <IonIcon
            icon={isPlaying ? pauseCircle : playCircle}
            onClick={togglePlay}
            className={styles.audioButton}
          />
        </div>
      )}
    </div>
  );
};

export default GameIntro;
