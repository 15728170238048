import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  useIonToast,
  IonImg
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../components/my-modal/my-modal";
import QrScanner from "../../components/qr-scanner/qr-scanner";
import { UserContext } from "../../contexts/user.context";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  GetMerchantByAddressOutput,
  Merchant,
} from "../../models/api/merchant.models";
import { TransferOutput } from "../../models/api/wallet.models";
import { UserStatus } from "../../models/contexts/user.context.model";
import { ErrorBody } from "../../models/generic";
import { Routes } from "../../routes";
import { get, post } from "../../services/http-service";
import {
  ENDPOINT_GET_BALANCE,
  ENDPOINT_GET_CHECK_RECEIPT,
  ENDPOINT_GET_MERCHANT_BY_ADDRESS,
  ENDPOINT_POST_WALLET_TRANSFER,
} from "../../variables";
import styles from "./wallet-page.module.css";
import { Clipboard } from '@capacitor/clipboard';

const WalletPage = () => {
  const userContext = useContext(UserContext);
  const [present] = useIonToast();
  const location = useLocation();
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState(false);
  const [merchant, setMerchant] = useState<Merchant>();
  const [fetchMerchantRequestStatus, setFetchMerchantRequestStatus] =
    useState("not_sent");
  const [transferRequestStatus, setTransferRequestStatus] =
    useState("not_sent");
  const [waitForUserFetch, setWaitForUserFetch] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceError, setBalanceError] = useState<ErrorBody>();
  const [transferError, setTransferError] = useState<ErrorBody>();
  const [fetchMerchantError, setFetchMerchantError] = useState<ErrorBody>();
  const { height, width } = useWindowDimensions();
  const [walletAddressCopied, setWalletAddressCopied] = useState(false);

  const countersThreshold = 10;

  useEffect(() => {
    if (location.pathname !== Routes.WALLET) return;

    if (userContext.user.status === UserStatus.LOGGED) {
      setMerchant(undefined);
      setFetchMerchantRequestStatus("not_sent");
      setTransferRequestStatus("not_sent");
      setIsPending(true);
      setBalanceError(undefined);
      setTransferError(undefined);
      fetchTokens().finally(() => setIsPending(false));
      // setTimeout(() => {
      //   fetchMerchantByAddress("0x4480373608907De7F0fe3f49a0Ce6D2904af7605");
      // }, 2000);
    } else {
      setWaitForUserFetch(true);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname !== Routes.WALLET) return;

    if (waitForUserFetch && userContext.user.status === UserStatus.LOGGED) {
      setIsPending(true);
      setBalanceError(undefined);
      setTransferError(undefined);
      fetchTokens().finally(() => setIsPending(false));
    }
  }, [userContext]);

  const showInvalidQrToast = () => {
    present({
      message: t("ui.invalidQr"),
      duration: 2500,
      position: "top",
      color: "primary",
      cssClass: "ion-text-center",
    });
  };

  async function fetchTokens() {
    try {
      const balance = await get<number>({
        endpoint: `${ENDPOINT_GET_BALANCE}/${userContext.user.id}`,
        bearerToken: userContext.user.token,
      });

      setBalance(balance);
    } catch (err) {
      setBalanceError({ message: t("wallet.balanceError") });
      console.log("error while fetching balance: ", err);
    }
  }
  async function fetchMerchantByAddress(address: string) {
    setFetchMerchantRequestStatus("pending");
    try {
      const merchantRes = await get<GetMerchantByAddressOutput>({
        endpoint: `${ENDPOINT_GET_MERCHANT_BY_ADDRESS}/${address}`,
        bearerToken: userContext.user.token,
      });

      if (!merchantRes.error) {
        setMerchant(merchantRes.merchant);
        setFetchMerchantRequestStatus("success");
      } else {
        setFetchMerchantRequestStatus("failure");
        setFetchMerchantError({ code: merchantRes.code });
      }
    } catch (err: any) {
      setFetchMerchantRequestStatus("failure");
      setFetchMerchantError({ message: err?.message ?? err });
      console.log("error while fetching merchant: ", err);
    }
  }

  async function onScanComplete(address: string) {
    await fetchMerchantByAddress(address);
  }

  async function sendTransfer() {
    setTransferRequestStatus("pending");
    try {
      const transferPayload = await post<TransferOutput>({
        endpoint: `${ENDPOINT_POST_WALLET_TRANSFER}`,
        bearerToken: userContext.user.token,
        data: {
          amount: 1,
          userId: merchant?.user.id,
        },
      });

      if (!transferPayload.error && !!transferPayload.tx) {
        setTransferRequestStatus("waiting");

        let transferTxStatus = "pending";
        let transferTxCheckCounter = 0;
        while (
          (transferTxStatus === "pending" || transferTxStatus === "error") &&
          transferTxCheckCounter < countersThreshold
        ) {
          await sleep(4000);
          transferTxCheckCounter++;
          transferTxStatus = await checkReceipt(transferPayload?.tx);
        }

        if (transferTxStatus === "success") {
          setTransferRequestStatus("success");
          await fetchTokens();
        } else if (transferTxStatus === "pending") {
          setTransferRequestStatus("failure");
          setTransferError({ message: t("wallet.transactionStillPending") });
        } else {
          setTransferRequestStatus("failure");
          setTransferError({ message: t("wallet.transactionFailed") });
        }
      } else {
        setTransferRequestStatus("failure");
        setTransferError({ code: transferPayload?.code ?? "generic" });
      }
    } catch (err: any) {
      setTransferRequestStatus("failure");
      setTransferError({ message: err?.message ?? err });
      console.log("error while fetching balance: ", err);
    }
  }

  async function checkReceipt(tx: string) {
    try {
      const res = await get<string>({
        endpoint: `${ENDPOINT_GET_CHECK_RECEIPT}/${tx}`,
        bearerToken: userContext.user.token,
      });
      return res;
    } catch (err) {
      return "error";
    }
  }

  async function sleep(ms: number) {
    await new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async function onRefresh(event: CustomEvent<RefresherEventDetail>) {
    setBalanceError(undefined);
    await fetchTokens();
    event.detail.complete();
  }

  const copyWalletAddress = async () => {
    if (userContext?.user?.wallet) {
      await Clipboard.write({
        string: userContext.user.wallet
      });
    }
    setWalletAddressCopied(true)
    setTimeout(() => {
      setWalletAddressCopied(false)
    }, 2000)
  }

  const sendModalContentNotSent = (
    <>
      {t("wallet.sendConfirm", {
        amount: 1,
        receiver: merchant?.user.username,
      })}
      <div className={styles.transferModalFooter}>
        <MyButtonWithBorder
          className={styles.transferModalBtn}
          color="secondary"
          onClick={() => {
            sendTransfer();
          }}
        >
          {t("ui.yes")}
        </MyButtonWithBorder>
        <MyButtonWithBorder
          className={styles.transferModalBtn}
          color="secondary"
          onClick={() => {
            setMerchant(undefined);
          }}
        >
          {t("ui.no")}
        </MyButtonWithBorder>
      </div>
    </>
  );

  return (
    <IonPage>
      <IonHeader className="ion-no-border backdrop-sensitive">
        <IonToolbar color="secondary" mode="ios">
          {/* <IonButtons slot="start">
            <IonButton
              routerLink={Routes.NFTS}
              color="tertiary"
              className={`${styles.nftNavbarBtn} ion-text-uppercase`}
            >
              NFTs
            </IonButton>
          </IonButtons> */}
          <IonTitle className={`${styles.walletTitle} ion-text-uppercase`}>
            Wallet
            <div>
              {userContext?.user?.wallet &&
                <span onClick={copyWalletAddress} className={styles.walletAddress}>{t("wallet.address")+userContext.user.wallet.slice(0, 6) + '...' + userContext.user.wallet.slice(-6)}
                  <IonImg
                    style={{ width: "12px", height: "12px", margin: "1px 0 0 2px" }}
                    className={styles.confirmImg}
                    src="assets/copy-outline.svg"
                    alt="Confirm"
                  ></IonImg></span>
              }
            </div>
          </IonTitle>
        </IonToolbar>
        <span className={(walletAddressCopied) ? styles.copiedAlert + ' ' + styles.copiedAlertVisible : styles.copiedAlert}>{t("ui.copied")}</span>
      </IonHeader>
      <IonContent color="secondary">
        {!isPending && !merchant && (
          <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        )}
        <div className={styles.viewContainer}>
          {isPending ? (
            <IonSpinner />
          ) : balanceError ? (
            <div>
              {balanceError?.code
                ? t(`errors.${balanceError?.code}`)
                : balanceError?.message}
            </div>
          ) : (
            <>
              <LazyLoadImage
                className={styles.walletImg}
                src="assets/wallet/wallet.svg"
                alt="Wallet"
                placeholder={<IonSpinner />}
              ></LazyLoadImage>
              <IonText className={styles.balanceText}>{balance} ZNZ</IonText>
            </>
          )}

          <MyModal
            isOpen={
              !merchant &&
              (fetchMerchantRequestStatus === "pending" ||
                fetchMerchantRequestStatus === "failure")
            }
            containerClassName={styles.transferModal}
          // onCloseClick={() => {
          //   setMerchantAddress("");
          // }}
          // onBackgroundClick={() => {
          //   setMerchantAddress("");
          // }}
          >
            <div>
              {fetchMerchantRequestStatus === "failure" ? (
                <>
                  <div>
                    {fetchMerchantError?.code
                      ? t(`errors.${fetchMerchantError?.code}`)
                      : fetchMerchantError?.message}
                  </div>
                  <div className={styles.transferModalFooter}>
                    <MyButtonWithBorder
                      className={styles.transferModalBtn}
                      color="secondary"
                      onClick={() => {
                        setMerchant(undefined);
                        setTransferRequestStatus("not_sent");
                        setTransferError(undefined);
                      }}
                    >
                      {t("ui.close")}
                    </MyButtonWithBorder>
                  </div>
                </>
              ) : (
                <IonSpinner />
              )}
            </div>
          </MyModal>
          <MyModal
            isOpen={!!merchant}
            containerClassName={styles.transferModal}
          // onCloseClick={() => {
          //   setMerchantAddress("");
          // }}
          // onBackgroundClick={() => {
          //   setMerchantAddress("");
          // }}
          >
            <div>
              {transferRequestStatus === "not_sent" ? (
                sendModalContentNotSent
              ) : transferRequestStatus === "pending" ? (
                <>
                  <IonSpinner />
                  <div>{t("wallet.pendingSend")}</div>
                </>
              ) : transferRequestStatus === "waiting" ? (
                <>
                  <IonSpinner />
                  <div>{t("wallet.waitingForBlockchain")}</div>
                </>
              ) : transferRequestStatus === "success" ? (
                <>
                  <div>{t("wallet.transferSuccess")}</div>
                  <div className={styles.transferModalFooter}>
                    <MyButtonWithBorder
                      className={styles.transferModalBtn}
                      color="secondary"
                      onClick={() => {
                        setMerchant(undefined);
                        setTransferRequestStatus("not_sent");
                        setTransferError(undefined);
                      }}
                    >
                      {t("ui.close")}
                    </MyButtonWithBorder>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    {transferError?.code
                      ? t(`errors.${transferError?.code}`)
                      : transferError?.message}
                  </div>
                  <div className={styles.transferModalFooter}>
                    <MyButtonWithBorder
                      className={styles.transferModalBtn}
                      color="secondary"
                      onClick={() => {
                        setMerchant(undefined);
                        setTransferRequestStatus("not_sent");
                        setTransferError(undefined);
                      }}
                    >
                      {t("ui.close")}
                    </MyButtonWithBorder>
                  </div>
                </>
              )}
            </div>
          </MyModal>

          {/* <IonText className={styles.balanceText}>{balance} BTC</IonText>
                <IonText className={styles.convertedText}>= {converted} EUR</IonText> */}
        </div>
      </IonContent>
    </IonPage>
  );
};
export default WalletPage;
