import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import BoxedTitle from "../../components/boxed-title/boxed-title";
import { ItineraryContext } from "../../contexts/itinerary.context";
import { UserContext } from "../../contexts/user.context";
import { ItineraryToken } from "../../models/api/itineraryTokens.models";
import { UserStatus } from "../../models/contexts/user.context.model";
import { Routes } from "../../routes";
import { get } from "../../services/http-service";
import { ENDPOINT_GET_ITINERARY_TOKENS_WITH_ITINERARY } from "../../variables";
import styles from "./user-itineraries-page.module.css";

const UserItinerariesPage = () => {
  const userContext = useContext(UserContext);
  const itineraryContext = useContext(ItineraryContext);

  const history = useHistory();
  const location = useLocation();
  const [isPending, setIsPending] = useState(false);
  const [waitForUserFetch, setWaitForUserFetch] = useState(false);
  const [isError, setIsError] = useState(false);

  const { t } = useTranslation();

  const [userItineraryTokens, setUserItineraryTokens] = useState<
    ItineraryToken[]
  >([]);

  useEffect(() => {
    if (location.pathname !== Routes.USER_ITINERARIES) return;

    if (userContext.user.status === UserStatus.LOGGED) {
      setIsPending(true);
      setIsError(false);
      fetchItineraries().finally(() => setIsPending(false));
    } else {
      setWaitForUserFetch(true);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname !== Routes.USER_ITINERARIES) return;

    if (waitForUserFetch && userContext.user.status === UserStatus.LOGGED) {
      setIsPending(true);
      setIsError(false);
      fetchItineraries().finally(() => setIsPending(false));
    }
  }, [userContext]);

  const userItinerariesHtml = userItineraryTokens.map(
    (itineraryToken, index) => {
      return (
        <IonButton
          className={styles.itineraryButton}
          key={index}
          fill="solid"
          color="secondary"
          onClick={() => onItineraryClick(itineraryToken)}
        >
          <div className={styles.itineraryBox}>
            <span className={styles.itineraryName}>
              {itineraryToken.itinerary.locales[0].name}
            </span>
            <span className={styles.itineraryCity}>
              {itineraryToken.itinerary.city}
            </span>
          </div>
        </IonButton>
      );
    }
  );

  function onItineraryClick(itineraryToken: ItineraryToken) {
    itineraryContext.startItinerary(itineraryToken);
  }

  async function fetchItineraries() {
    try {
      const itineraryTokens = await get<ItineraryToken[]>({
        endpoint: `${ENDPOINT_GET_ITINERARY_TOKENS_WITH_ITINERARY}/${userContext.user.id}`,
        bearerToken: userContext.user.token,
      });

      setUserItineraryTokens(itineraryTokens);
      const play = localStorage.getItem("play");
      if (play && play !== "") {
        const itineraryToken = itineraryTokens.find(
          (token) => token.itinerary.id === parseInt(play)
        );
        if (itineraryToken) {
          localStorage.removeItem("play");
          onItineraryClick(itineraryToken);
        }
      }
    } catch (err) {
      // TODO: handle error case
      setIsError(true);
      console.log("error while fetching user itineraries: ", err);
    }
  }

  async function onRefresh(event: CustomEvent<RefresherEventDetail>) {
    setIsError(false);
    await fetchItineraries();
    event.detail.complete();
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton style={{ fontSize: "30px" }}></IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!isPending && (
          <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        )}
        <div className={styles.upperSectionContainer}>
          <BoxedTitle className={styles.itineraryTitle}>
            {t("userItineraries.myItineraries")}
          </BoxedTitle>
        </div>
        <div className={styles.bottomSectionContainer}>
          {isPending ? (
            <IonSpinner />
          ) : isError ? (
            <div>{t("userItineraries.error")}</div>
          ) : userItinerariesHtml.length === 0 ? (
            <div>{t("userItineraries.noItineraries")}</div>
          ) : (
            <div className={styles.itinerariesContainer}>
              {userItinerariesHtml}
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UserItinerariesPage;
