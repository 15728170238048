import { IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { PLAY_STATE, QuizAnswer } from "../../../models/games/base.models";
import {
  RiddleConfig,
  RiddleGameState,
} from "../../../models/games/riddle.models";
import { Routes } from "../../../routes";
import AnswerButton from "./answer-button";
import styles from "./riddle.module.css";

interface RiddleProps {
  config: RiddleConfig;
  imageUrl: string;
  onWon?: (arg0: number) => void;
  onLost?: (arg0: number) => void;
}

type RiddleAnswerState = QuizAnswer & { clicked: boolean };

const Riddle = (props: RiddleProps) => {
  const { t } = useTranslation();

  const [answers, setAnswers] = useState<RiddleAnswerState[]>([]);
  const [gameState, setGameState] = useState<RiddleGameState>({
    playState: PLAY_STATE.NOT_STARTED,
    score: 0,
  });
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_RIDDLE)) return;

    setAnswers(
      props.config.answers.map<RiddleAnswerState>((el) => ({
        ...el,
        clicked: false,
      }))
    );
    setGameState({ playState: PLAY_STATE.PLAYING, score: 0 });
  }, [location]);

  useEffect(() => {
    if (gameState.playState === PLAY_STATE.WON) {
      props.onWon?.(gameState.score);
    } else if (gameState.playState === PLAY_STATE.LOST) {
      props.onLost?.(gameState.score);
    }
  }, [props, gameState]);

  function onAnswer(answerState: RiddleAnswerState) {
    if (gameState.playState !== PLAY_STATE.PLAYING) return;

    const tmp = answers.map((el) => ({
      ...el,
      clicked: el.clicked || el.answer === answerState.answer,
    }));
    setAnswers(tmp);

    if (answerState.isCorrect) {
      setGameState({
        ...gameState,
        score: props.config.reward,
        playState: PLAY_STATE.WON,
      });
    } else {
      setGameState({ ...gameState, playState: PLAY_STATE.LOST });
    }
  }

  // TODO: backend should check there are no duplicated answers when creating a game
  const answersHtml = answers.map((answer, i) => {
    let btnClass = "";
    if (answer.clicked) {
      btnClass = answer.isCorrect ? styles.correctAnswer : styles.wrongAnswer;
    } else if (answer.isCorrect && gameState.playState === PLAY_STATE.LOST) {
      btnClass = styles.correctAnswer;
    }

    return (
      <AnswerButton
        className={btnClass}
        onClick={() => onAnswer(answer)}
        key={`${answer.answer}-${i}`}
      >
        {answer.answer}
      </AnswerButton>
    );
  });

  return (
    <>
      <div>
        {/* <LazyLoadImage
          className={styles.upperImage}
          src={props.imageUrl}
          alt="Riddle upper image"
          placeholder={<IonSpinner className="centered" />}
        ></LazyLoadImage> */}
        <p
          className={`${styles.riddleSubtitle} ion-text-uppercase ion-text-center`}
        >
          {t("games.resolveTheRiddle")}
        </p>
      </div>
      <LazyLoadImage
        src="assets/games/icons/riddle.svg"
        className={styles.riddleImage}
        alt="Riddle"
        placeholder={<IonSpinner />}
      ></LazyLoadImage>
      <p
        className={`${styles.riddleDescription} zonzo-scrollbar-primary ion-text-center`}
        dangerouslySetInnerHTML={props.config.question ? { __html: props.config.question } : { __html: "" }}
      ></p>
      <div className={styles.answerCtn}>{answersHtml}</div>
    </>
  );
};

export default Riddle;
