import { IonButton, IonImg, IonSpinner, isPlatform } from "@ionic/react";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { PLAY_STATE, QuizAnswer } from "../../../models/games/base.models";
import {
  DrawingsAndWordsConfig,
  DrawingsAndWordsGameState,
} from "../../../models/games/drawings-and-words.models";
import { Routes } from "../../../routes";
import { GAME_IMAGES_FOLDER } from "../../../variables";
import MyWindow from "../../my-window/my-window";
import EndgameWindow from "../endgame-window/endgame-window";
import AnswerButton from "./answer-button";
import styles from "./drawings-and-words.module.css";

interface DrawingsAndWordsProps {
  config: DrawingsAndWordsConfig;
  imageUrl: string;
  onWon?: (arg0: number) => void;
  onLost?: (arg0: number) => void;
  onEndgameTouch?: () => void;
}

const DrawingsAndWords = (props: DrawingsAndWordsProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [gameState, setGameState] = useState<DrawingsAndWordsGameState>({
    playState: PLAY_STATE.NOT_STARTED,
    score: 0,
  });
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME_MATCH)) return;

    setSelectedIndex(-1);
    setGameState({ playState: PLAY_STATE.PLAYING, score: 0 });
  }, [location]);

  useEffect(() => {
    if (gameState.playState === PLAY_STATE.WON) {
      props.onWon?.(gameState.score);
    } else if (gameState.playState === PLAY_STATE.LOST) {
      props.onLost?.(gameState.score);
    }
  }, [props, gameState]);

  /**
   * When the answer is clicked, the state is updated with the new clicked value
   * @param answerState
   * @param answerIndex
   * @returns
   */
  function onClickAnswer(answerState: QuizAnswer, answerIndex: number) {
    if (gameState.playState !== PLAY_STATE.PLAYING) return;

    setSelectedIndex(answerIndex);
  }

  /**
   * if an answer is clicked but it is not correct, return false
   * @returns
   */
  function validateAnswer(): boolean {
    return props.config.answers[selectedIndex].isCorrect;
  }

  function thereIsClickedAnswer(): boolean {
    return selectedIndex !== -1;
  }

  /**
   * When I submit answers, they are validated and the game state is updated
   */
  function onSendAnswer() {
    if (validateAnswer()) {
      setGameState({ score: props.config.reward, playState: PLAY_STATE.WON });
    } else {
      setGameState({ ...gameState, playState: PLAY_STATE.LOST });
    }
  }

  // TODO: backend should check there are no duplicated answers when creating a game
  const answersHtml = props.config.answers.map((answer, i) => {
    let btnClass = "";
    if (i === selectedIndex) {
      btnClass = styles.clickedAnswer;
    }

    return (
      <AnswerButton
        containerClassName={styles.imageButtonContainer}
        className={`${styles.imageButton} ${btnClass}`}
        onClick={() => onClickAnswer(answer, i)}
        key={i}
      >
        <LazyLoadImage
          className={styles.answerImg}
          src={`${GAME_IMAGES_FOLDER}/${answer.answer}`}
          alt={`Risposta n. ${i}`}
          placeholder={<IonSpinner />}
        />
      </AnswerButton>
    );
  });

  const correctAnswerIndex = props.config.answers.findIndex(
    (answer) => answer.isCorrect
  );
  const correctAnswer = props.config.answers[correctAnswerIndex].answer;

  return (
    <>
      {/* <LazyLoadImage
        className={styles.upperImage}
        src={props.imageUrl}
        alt="Single choice quiz upper image"
        placeholder={<IonSpinner />}
      ></LazyLoadImage> */}
      {gameState.playState === PLAY_STATE.PLAYING ||
      gameState.playState === PLAY_STATE.NOT_STARTED ? (
        <MyWindow className={styles.quizWindow}>
          <div className={styles.answersContainer}>{answersHtml}</div>
          <p
            className={`${styles.questionText} ${
              isPlatform("android") ? styles.questionTextAndroid : ""
            } ion-text-center zonzo-scrollbar-primary`}
          >
            {props.config.question}
          </p>
          <IonButton
            disabled={
              gameState.playState !== PLAY_STATE.PLAYING ||
              !thereIsClickedAnswer()
            }
            className={styles.confirmButton}
            fill="clear"
            onClick={() => onSendAnswer()}
          >
            <IonImg
              className={styles.confirmImg}
              src="assets/games/tick.svg"
              alt="Confirm"
            ></IonImg>
          </IonButton>
        </MyWindow>
      ) : (
        <EndgameWindow
          correctAnswer={
            <LazyLoadImage
              src={`${GAME_IMAGES_FOLDER}/${correctAnswer}`}
              alt={`Risposta n. ${correctAnswerIndex}`}
              className={styles.correctImg}
              placeholder={<IonSpinner />}
            />
          }
          won={gameState.playState === PLAY_STATE.WON}
          onWindowTouchStart={() => {
            if (props?.onEndgameTouch) props.onEndgameTouch();
          }}
        />
      )}
    </>
  );
};

export default DrawingsAndWords;
