import { IonButton, IonImg, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { PLAY_STATE } from "../../../models/games/base.models";
import {
  HangmanConfig,
  HangmanGameState,
} from "../../../models/games/hangman.models";
import { Routes } from "../../../routes";
import MyWindow from "../../my-window/my-window";
import SimpleInputField from "../../simple-input-field/simple-input-field";
import EndgameWindow from "../endgame-window/endgame-window";
import styles from "./hangman.module.css";

interface HangmanProps {
  config: HangmanConfig;
  imageUrl: string;
  onWon?: (arg0: number) => void;
  onLost?: (arg0: number) => void;
  onEndgameTouch?: () => void;
}

const Hangman = (props: HangmanProps) => {
  const [gameState, setGameState] = useState<HangmanGameState>({
    playState: PLAY_STATE.NOT_STARTED,
    score: 0,
  });
  const [matchingLetters, setMatchingLetters] = useState<string[]>([]);
  const [lives, setLives] = useState<number>(6);
  const [characterBuffer, setCharacterBuffer] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME_MATCH)) return;

    setMatchingLetters([]);
    setLives(6);
    setCharacterBuffer(undefined);
    setGameState({ playState: PLAY_STATE.PLAYING, score: 0 });
  }, [location]);

  useEffect(() => {
    if (gameState.playState === PLAY_STATE.WON) {
      props.onWon?.(gameState.score);
    } else if (gameState.playState === PLAY_STATE.LOST) {
      props.onLost?.(gameState.score);
    }
  }, [props, gameState]);

  const answerArray = props.config.answer.toUpperCase().split("");
  const dinstinctAnswerArray = answerArray.filter(function (item, pos) {
    return answerArray.indexOf(item) === pos;
  });

  function isGuessedLetter(character: string) {
    return matchingLetters.includes(character);
  }

  function onInputChange(e: any) {
    setCharacterBuffer(e.target.value.toUpperCase());
  }

  function onSendAnswer() {
    if (characterBuffer) {
      if (dinstinctAnswerArray.includes(characterBuffer)) {
        if (!isGuessedLetter(characterBuffer)) {
          const newMatchingLetters = [...matchingLetters, characterBuffer];
          setMatchingLetters(newMatchingLetters);
          if (newMatchingLetters.length === dinstinctAnswerArray.length) {
            setGameState({
              ...gameState,
              score: props.config.reward,
              playState: PLAY_STATE.WON,
            });
          }
        }
      } else {
        const newLives = lives - 1;
        setLives(newLives);
        if (newLives === 0) {
          setGameState({ ...gameState, playState: PLAY_STATE.LOST });
        }
      }
    }
  }

  // TODO: backend should check there are no duplicated answers when creating a game
  const tilesHtml = answerArray.map((character, i) => {
    return (
      <div className={styles.answerCharacter} key={i}>
        {isGuessedLetter(character) ? character : "_"}
      </div>
    );
  });

  return (
    <>
      {/* <LazyLoadImage
        className={styles.upperImage}
        src={props.imageUrl}
        alt="Single choice quiz upper image"
        placeholder={<IonSpinner />}
      ></LazyLoadImage> */}
      {gameState.playState === PLAY_STATE.PLAYING ||
      gameState.playState === PLAY_STATE.NOT_STARTED ? (
        <MyWindow className={styles.quizWindow}>
          <div className={styles.hangmanWindowUpperSection}>
            <p className={`${styles.questionText} ion-text-center`}>
              {props.config.question}
            </p>
            <div className={styles.answerCharactersContainer}>{tilesHtml}</div>
            {/* <SimpleInputField value={characterBuffer} maxlenght={1} className={styles.inputField} onKeyDown={(e) => onInputChange(e)} label="" type="text"></SimpleInputField> */}
            <SimpleInputField
              maxlenght={1}
              className={styles.inputField}
              onChange={(e) => onInputChange(e)}
              label=""
              type="text"
            ></SimpleInputField>
          </div>
          <LazyLoadImage
            className={styles.hangmanImage}
            src="assets/games/icons/hangman.svg"
            alt="Hangman"
            placeholder={<IonSpinner />}
          ></LazyLoadImage>
          <div className={styles.hangmanWindowBottomSection}>
            <div className={styles.hangmanWindowLivesCounter}>{lives}</div>
            <IonButton
              disabled={
                gameState.playState !== PLAY_STATE.PLAYING ||
                !characterBuffer ||
                characterBuffer === ""
              }
              className={styles.confirmButton}
              fill="clear"
              onClick={() => onSendAnswer()}
            >
              <IonImg
                className={styles.confirmImg}
                src="assets/games/tick.svg"
                alt="Confirm"
              ></IonImg>
            </IonButton>
          </div>
        </MyWindow>
      ) : (
        <EndgameWindow
          correctAnswer={props.config.answer}
          won={gameState.playState === PLAY_STATE.WON}
          onWindowTouchStart={() => {
            if (props?.onEndgameTouch) props.onEndgameTouch();
          }}
        />
      )}
    </>
  );
};

export default Hangman;
