import { IonContent, IonHeader, IonPage, IonSpinner } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import Navbar from "../../../components/navbar/navbar";
import WindowWithStroke from "../../../components/window-with-stroke/window-with-stroke";
import { ItineraryContext } from "../../../contexts/itinerary.context";
import { UserContext } from "../../../contexts/user.context";
import {
  MintRequestCreateOutput,
  MintRequestMintOutput,
  // MintRequestTransferOutput,
} from "../../../models/api/mintRequest.models";
import { CheckReceiptOutput } from "../../../models/api/wallet.models";
import { Routes } from "../../../routes";
import { get, post } from "../../../services/http-service";
import {
  // ENDPOINT_GET_BALANCE,
  ENDPOINT_GET_CHECK_RECEIPT,
  ENDPOINT_POST_CREATE_SCORE_ENTRY,
  ENDPOINT_POST_MINT_REQUEST_CREATE,
  ENDPOINT_POST_MINT_REQUEST_MINT,
  // ENDPOINT_POST_MINT_REQUEST_TRANSFER,
  TAG_IMAGES_FOLDER,
} from "../../../variables";
import styles from "./itinerary-complete.module.css";

const ItineraryCompletePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const itineraryContext = useContext(ItineraryContext);
  const userContext = useContext(UserContext);
  const [pageProcessStep, setPageProcessStep] = useState("mint");
  // const [mintRequestValue, setMintRequestValue] = useState("");
  // const [mintTx, setMintTx] = useState("");
  // const [transferTx, setTransferTx] = useState("");
  const [mintError, setMintError] = useState("");
  // const [nftInfo, setNftInfo] = useState({ image: "" });

  const tagImageUrl = `${TAG_IMAGES_FOLDER}/${itineraryContext.currentItinerary?.tags[0].logoName}`;
  const countersThreshold = 10;

  useEffect(() => {
    async function onPageEnter() {
      toggleNavigation(true);
      // await fetchNFTInfo();
      await sendScore();
      await doMintingProcess();
      await itineraryContext.endItinerary();
      toggleNavigation(false);
    }

    if (location.pathname !== Routes.ITINERARY_COMPLETE) return;
    onPageEnter();
  }, [location]);

  async function toggleNavigation(value: boolean) {
    document
      .querySelectorAll(".backdrop-sensitive")
      .forEach((el) => el.classList.toggle("semitransparent-active", value));
  }
  async function sendScore() {
    // call to add score
    if (itineraryContext.currentScore > 0) {
      setPageProcessStep("score");
      try {
        await post({
          endpoint: ENDPOINT_POST_CREATE_SCORE_ENTRY,
          bearerToken: userContext.user.token,
          data: {
            userId: userContext.user.id,
            itineraryId: itineraryContext.currentItinerary?.id,
            value: itineraryContext.currentScore,
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  }
  async function doMintingProcess() {
    setPageProcessStep("create");
    const mintRequest = await sendMintRequestCreate();
    if (!!mintRequest) {
      setPageProcessStep("mint");
      const mintTx = await sendMintRequestMint(mintRequest);
      if (!!mintTx) {
        setPageProcessStep("mint-check");
        let mintTxStatus = "pending";
        let mintTxCheckCounter = 0;
        while (
          (mintTxStatus === "pending" || mintTxStatus === "error") &&
          mintTxCheckCounter < countersThreshold
        ) {
          await sleep(4000);
          mintTxStatus = await checkReceipt(mintTx);
        }

        if (mintTxStatus === "success") {
          setPageProcessStep("end");
          // if (!itineraryContext?.currentItinerary?.nft_hash) {
          //   setPageProcessStep("transfer");
          //   const transferTx = await sendMintRequestTransfer(mintRequest);
          //   if (!!transferTx) {
          //     setPageProcessStep("transfer-check");
          //     let transferTxStatus = "pending";
          //     let transferTxCheckCounter = 0;
          //     while (
          //       (transferTxStatus === "pending" ||
          //         transferTxStatus === "error") &&
          //       transferTxCheckCounter < countersThreshold
          //     ) {
          //       await sleep(4000);
          //       transferTxStatus = await checkReceipt(transferTx);
          //     }

          //     if (transferTxStatus === "success") {
          //       setPageProcessStep("balance");
          //       await fetchTokens();
          //     }
          //   }
          // } else {
          //   setPageProcessStep("end");
          // }
        }
      }
    }
  }
  async function sendMintRequestCreate() {
    setMintError("");
    // setMintRequestValue("");
    // setMintRequestCreateStatus("pending");
    try {
      const res = await post<MintRequestCreateOutput>({
        endpoint: ENDPOINT_POST_MINT_REQUEST_CREATE,
        bearerToken: userContext.user.token,
        data: {
          itineraryToken: itineraryContext.itineraryToken,
        },
      });
      // setMintRequestValue(res.mintRequestValue);
      return res.mintRequestValue;
    } catch (err: any) {
      setMintError(err?.response?.data?.message ?? err?.message ?? err);
      return false;
    }
  }
  async function sendMintRequestMint(mintRequestValue: string) {
    try {
      const res = await post<MintRequestMintOutput>({
        endpoint: ENDPOINT_POST_MINT_REQUEST_MINT,
        bearerToken: userContext.user.token,
        data: {
          value: mintRequestValue,
        },
      });
      // setMintTx(res.tx);
      return res.tx;
    } catch (err: any) {
      setMintError(err?.response?.data?.message ?? err?.message ?? err);
      return false;
    }
  }
  // async function sendMintRequestTransfer(mintRequestValue: string) {
  //   try {
  //     const res = await post<MintRequestTransferOutput>({
  //       endpoint: ENDPOINT_POST_MINT_REQUEST_TRANSFER,
  //       bearerToken: userContext.user.token,
  //       data: {
  //         value: mintRequestValue,
  //       },
  //     });
  //     setTransferTx(res.tx);
  //     return res.tx;
  //   } catch (err: any) {
  //     setMintError(err?.response?.data?.message ?? err?.message ?? err);
  //     return false;
  //   }
  // }
  async function checkReceipt(tx: string) {
    try {
      const res = await get<CheckReceiptOutput>({
        endpoint: `${ENDPOINT_GET_CHECK_RECEIPT}/${tx}`,
        bearerToken: userContext.user.token,
      });
      return res;
    } catch (err: any) {
      return "error";
    }
  }
  // async function fetchTokens() {
  //   try {
  //     await get<number>({
  //       endpoint: `${ENDPOINT_GET_BALANCE}/${userContext.user.id}`,
  //       bearerToken: userContext.user.token,
  //     });
  //     setPageProcessStep("end");
  //   } catch (err: any) {
  //     setMintError(err?.response?.data?.message ?? err?.message ?? err);
  //   }
  // }
  async function sleep(ms: number) {
    await new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  // async function fetchNFTInfo() {
  //   try {
  //     const res: any = await get({
  //       endpoint: `https://gateway.niftyz.tools/open/metadata/${itineraryContext?.currentItinerary?.nft_hash}`,
  //     });
  //     setNftInfo(res);
  //   } catch {}
  // }

  let pageContent;
  if (mintError !== "") {
    pageContent = (
      <>
        <span style={{ textAlign: "center" }}>{mintError}</span>
        <MyButtonWithBorder
          routerLink={Routes.WALLET}
          className={`${styles.walletBtn} ion-text-uppercase ${styles.marginTop}`}
          size="large"
          fill="solid"
          color="secondary"
        >
          {t("wallet.wallet")}
        </MyButtonWithBorder>
      </>
    );
  } else {
    if (pageProcessStep !== "end") {
      pageContent = <span>{t(`itineraryComplete.${pageProcessStep}`)}</span>;
    } else {
      pageContent = (
        <>
          <WindowWithStroke
            className={styles.itineraryCompleteWindow}
            imageUrl={"assets/games/coins.svg"}
            text={t("itineraryComplete.congratulations")}
            title={`${t("itineraryComplete.title")} 1 token`}
          />
          <MyButtonWithBorder
            routerLink={Routes.WALLET}
            className={`${styles.walletBtn} ion-text-uppercase`}
            size="large"
            fill="solid"
            color="secondary"
          >
            {t("wallet.wallet")}
          </MyButtonWithBorder>
        </>
      );
    }
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar
          nextButtonDisabled={true}
          backButtonDisabled={true}
          toolbarColor="primary"
        />
      </IonHeader>
      <IonContent color="primary">
        {mintError === "" && pageProcessStep === "end" ? (
          <div className={styles.itineraryCompleteContainer}>
            <LazyLoadImage
              className={styles.upperImage}
              src={tagImageUrl}
              alt="Itinerary tag image"
              placeholder={<IonSpinner />}
            ></LazyLoadImage>
            {pageContent}
          </div>
        ) : (
          <div className={styles.itineraryCompleteContainerMessage}>
            {pageContent}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ItineraryCompletePage;
