import { Storage } from "@ionic/storage";

let storageSingleton: Storage;

export async function createStorage() {
  storageSingleton = new Storage();
  await storageSingleton.create();
}

export async function get(key: string): Promise<any> {
  return localStorage.getItem(key);
  // return await storageSingleton.get(key);
}

export async function set(key: string, value: any): Promise<void> {
  if (value === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
  // return await storageSingleton.set(key, value);
}
