import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import { helpOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { v4 } from "uuid";
import GameIntro from "../../../components/games/game-intro/game-intro";
import Riddle from "../../../components/games/riddle/riddle";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../../components/my-modal/my-modal";
import Navbar from "../../../components/navbar/navbar";
import { ItineraryContext } from "../../../contexts/itinerary.context";
import { GAME_TYPE } from "../../../models/api/games.models";
import { Routes } from "../../../routes";
import { TAG_IMAGES_FOLDER } from "../../../variables";
import styles from "./waypoint-game.module.css";

const WaypointGamePage = () => {
  const { t } = useTranslation();
  const itineraryContext = useContext(ItineraryContext);
  const [gameEnded, setGameEnded] = useState<boolean>(false);
  const [hintModalOpen, setHintModalOpen] = useState<boolean>(false);
  const [myIndex, setMyIndex] = useState<number>(0);
  // const [isPending, setIsPending] = useState(false);
  const [hintKey, setHintKey] = useState<string>("");
  const [myKey, setMyKey] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME + "/")) return;

    setMyKey(v4());
    setHintKey(v4());
    setGameEnded(false);
    setHintModalOpen(false);
    setMyIndex(itineraryContext.gameIndex);
  }, [location]);

  if (!itineraryContext?.currentWaypoint?.games.length || myIndex < 0)
    return fallbackPage();

  const games = itineraryContext?.currentWaypoint
    ? itineraryContext.currentWaypoint.games
    : [];

  const tagImageUrl = `${TAG_IMAGES_FOLDER}/${itineraryContext.currentItinerary?.tags[0].logoName}`;
  const currentGameId = myIndex;
  const currentGameLocale =
    games.length > currentGameId
      ? games[currentGameId].locales?.[0]
      : undefined;
  const currentGameConfig = !!currentGameLocale
    ? JSON.parse(currentGameLocale.config)
    : undefined;

  if (myIndex >= games.length) return fallbackPage();

  const gameImageObj = {
    url: `assets/games/icons/${games?.[currentGameId]?.type}.svg`,
    alt: currentGameLocale?.title ?? "",
  };

  // const uniqueId = `${itineraryContext?.currentItinerary?.id}-${itineraryContext?.currentWaypoint?.id}-${currentGameId}`;

  const onGameEnded = (score: number) => {
    if (!gameEnded) {
      itineraryContext.addScore(score);
      setGameEnded(true);
    }
  };

  let gameTag;
  switch (games?.[currentGameId]?.type) {
    case GAME_TYPE.RIDDLE:
      gameTag = (
        <>
          <Riddle
            key={myKey}
            config={currentGameConfig}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          />
          <MyButtonWithBorder
            disabled={!gameEnded}
            onClick={() => itineraryContext.continue()}
            size="large"
            color="secondary"
            className={`${styles.continueButton} ion-text-uppercase`}
          >
            {t("ui.continue")}
          </MyButtonWithBorder>
        </>
      );
      break;
    default:
      gameTag = (
        <>
          <GameIntro
            key={myKey}
            gameId={games[currentGameId].id}
            gameImage={gameImageObj}
            upperImageUrl={tagImageUrl ?? ""}
            gameName={t(`games.name.${games[currentGameId].type}`)}
            gameDescription={currentGameLocale?.description ?? ""}
          ></GameIntro>
          <MyButtonWithBorder
            routerLink={`${Routes.WAYPOINT_GAME_MATCH}/${currentGameId}`}
            size="large"
            color="secondary"
            className={`${styles.continueButton} ion-text-uppercase`}
          >
            {t("ui.play")}
          </MyButtonWithBorder>
        </>
      );
      break;
  }

  function fallbackPage() {
    return (
      <IonPage>
        <IonHeader className="ion-no-border">
          <Navbar nextButtonDisabled={true} backButtonDisabled={true} />
        </IonHeader>
        <IonContent color="secondary">
          <div className={styles.fallbackContainer}>
            <IonSpinner />
          </div>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} backButtonDisabled={true}>
          {games?.[currentGameId]?.type === GAME_TYPE.RIDDLE && (
            <IonButtons slot="end">
              <IonButton
                onClick={() => setHintModalOpen(true)}
                color="tertiary"
                className={`${styles.hintBtn} next secondary-font`}
              >
                <IonIcon className="help-icon" icon={helpOutline} />
              </IonButton>
            </IonButtons>
          )}
        </Navbar>
      </IonHeader>
      <IonContent color="secondary">
        <div className={styles.waypointGameContainer}>
          {/* <div key={uniqueId} className={styles.waypointGameContainer}> */}
          {!!tagImageUrl && !!currentGameConfig ? (
            gameTag
          ) : (
            <p>{t("errors.unavailablePage")}</p>
          )}
        </div>
        {games?.[currentGameId]?.type === GAME_TYPE.RIDDLE && (
          <MyModal
            isOpen={hintModalOpen}
            containerClassName={styles.hintModal}
            onCloseClick={() => setHintModalOpen(false)}
            onBackgroundClick={() => setHintModalOpen(false)}
          >
            <p
              key={hintKey}
              className={`${styles.hintParagraph} zonzo-scrollbar-primary`}
            >
              {/* <p key={uniqueId} className={styles.hintParagraph}> */}
              {currentGameLocale?.hint}
            </p>
          </MyModal>
        )}
      </IonContent>
    </IonPage>
  );
};

export default WaypointGamePage;
