import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import MyAutocomplete from "../../components/my-autocomplete/my-autocomplete";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import { SelectionContext } from "../../contexts/selection.context";
import { UserContext } from "../../contexts/user.context";
import { UserStatus } from "../../models/contexts/user.context.model";
import { Routes } from "../../routes";
import { get } from "../../services/http-service";
import { ENDPOINT_GET_AVAILABLE_CITIES } from "../../variables";
import styles from "./city-selection.module.css";

const CitySelectionPage = () => {
  const userContext = useContext(UserContext);
  const [availableCities, setAvailableCities] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState<string>();
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation();
  const selectionContext = useContext(SelectionContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== Routes.CITY_SELECTION) return;

    async function fetchAvailableCities() {
      try {
        setIsPending(true);
        const res = await get<string[]>({
          endpoint: ENDPOINT_GET_AVAILABLE_CITIES,
        });
        setAvailableCities(res);
        setIsPending(false);
      } catch (err) {
        // TODO: handle error case
        setIsPending(false);
      }
    }

    fetchAvailableCities();
  }, [location, userContext.user.token]);

  // const resolverSchema = object({
  //   city: string().required(),
  // }).required()

  // const { control, handleSubmit, formState: {errors} } = useForm({resolver: yupResolver(resolverSchema)});

  // const formField: InputFieldControllerProps = {
  //   control,
  //   label: 'CITTÀ',
  //   name: 'city',
  //   type: 'text',
  //   placeholder: 'città'
  // }

  // const submit = (e: any) => {
  //   selectionContext.selectCity(e.city);
  //   history.push(Routes.TAG_SELECTION);
  // }

  const onSubmit = () => {
    if (selectedCity) {
      selectionContext.selectCity(selectedCity);
      history.push(Routes.CITY_ITINERARIES);
    }
  };

  const myAutocompleteItems = availableCities.map((city: string) => ({
    id: city.toLowerCase(),
    name: city,
  }));

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          {userContext.user.status === UserStatus.LOGGED && (
            <IonButtons slot="start">
              <IonMenuButton style={{ fontSize: "30px" }}></IonMenuButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div className={styles.citySelectionContainer}>
          <div className={styles.citySelectionForm}>
            <p className={styles.citySelectionDescription}>
              {t("citySelection.description")}
            </p>
            {t("labels.city").toUpperCase()}
            <MyAutocomplete
              className={styles.citySelectionAutocomplete}
              onClear={() => setSelectedCity("")}
              onSearch={(e) => setSelectedCity("")}
              onSelect={(e) => setSelectedCity(e.id)}
              label={""}
              items={myAutocompleteItems}
            />
            <MyButtonWithBorder
              disabled={!selectedCity || selectedCity === ""}
              onClick={onSubmit}
              size="large"
              color="secondary"
              className={`${styles.discoverButton} ion-text-uppercase`}
            >
              {t("ui.discover")}
            </MyButtonWithBorder>
            {/* <form onSubmit={handleSubmit(submit)}>
                <InputFieldController {...formField} errorMsg={errors[formField.name]?.message}/>
                <MyButtonWithBorder size='large' type='submit' color='secondary' className={`${styles.discoverButton} ion-text-uppercase`}>{t('ui.discover')}</MyButtonWithBorder>
              </form> */}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default CitySelectionPage;
