import { IonButton, IonImg, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { PLAY_STATE } from "../../../models/games/base.models";
import {
  SingleChoiceQuizConfig,
  SingleChoiceQuizGameState,
} from "../../../models/games/single-choice-quiz.models";
import { Routes } from "../../../routes";
import MyWindow from "../../my-window/my-window";
import SimpleInputField from "../../simple-input-field/simple-input-field";
import EndgameWindow from "../endgame-window/endgame-window";
import styles from "./single-choice-quiz.module.css";

interface SingleChoiceQuizProps {
  config: SingleChoiceQuizConfig;
  imageUrl: string;
  onWon?: (arg0: number) => void;
  onLost?: (arg0: number) => void;
  onEndgameTouch?: () => void;
}

const SingleChoiceQuiz = (props: SingleChoiceQuizProps) => {
  const [answer, setAnswer] = useState("");
  const [gameState, setGameState] = useState<SingleChoiceQuizGameState>({
    playState: PLAY_STATE.NOT_STARTED,
    score: 0,
  });
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME_MATCH)) return;

    setAnswer("");
    setGameState({ playState: PLAY_STATE.PLAYING, score: 0 });
  }, [location]);

  useEffect(() => {
    if (gameState.playState === PLAY_STATE.WON) {
      props.onWon?.(gameState.score);
    } else if (gameState.playState === PLAY_STATE.LOST) {
      props.onLost?.(gameState.score);
    }
  }, [props, gameState]);

  function onAnswer() {
    if (gameState.playState !== PLAY_STATE.PLAYING) return;

    if (props.config.answer.toLowerCase() === answer.trim().toLowerCase()) {
      setGameState({
        ...gameState,
        score: props.config.reward,
        playState: PLAY_STATE.WON,
      });
    } else {
      setGameState({ ...gameState, playState: PLAY_STATE.LOST });
    }
  }

  function onChange(text: string) {
    setAnswer(text);
  }

  return (
    <>
      {/* <LazyLoadImage
        className={styles.upperImage}
        src={props.imageUrl}
        alt="Single choice quiz upper image"
        placeholder={<IonSpinner />}
      ></LazyLoadImage> */}
      {gameState.playState === PLAY_STATE.PLAYING ||
      gameState.playState === PLAY_STATE.NOT_STARTED ? (
        <MyWindow className={styles.quizWindow}>
          <div>
            <p className={`${styles.questionText} ion-text-center`}>
              {props.config.question}
            </p>
            <SimpleInputField
              className={styles.inputField}
              onChange={(e) => onChange(e.target.value)}
              label=""
              type="text"
            ></SimpleInputField>
          </div>
          <IonButton
            disabled={
              gameState.playState !== PLAY_STATE.PLAYING || answer === ""
            }
            className={styles.confirmButton}
            fill="clear"
            onClick={() => onAnswer()}
          >
            <IonImg
              className={styles.confirmImg}
              src="assets/games/tick.svg"
              alt="Confirm"
            ></IonImg>
          </IonButton>
        </MyWindow>
      ) : (
        <EndgameWindow
          correctAnswer={props.config.answer}
          won={gameState.playState === PLAY_STATE.WON}
          onWindowTouchStart={() => {
            if (props?.onEndgameTouch) props.onEndgameTouch();
          }}
        />
      )}
    </>
  );
};

export default SingleChoiceQuiz;
