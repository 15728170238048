import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
  isPlatform,
  useIonToast,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import { UserContext } from "../../contexts/user.context";
import { Routes } from "../../routes";
import styles from "./access-page.module.css";

const AccessPage = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const origin = window.location.host;
  const [socialLoginRequestStatus, setSocialLoginRequestStatus] =
    useState("not_sent");
  const [present] = useIonToast();

  useEffect(() => {
    if (location.pathname !== Routes.ACCESS) return;
    setSocialLoginRequestStatus("not_sent");
  }, [location]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const session = url.searchParams.get("session");
    if (session !== null && session !== undefined) {
      console.log("Found session", session);
      const decoded = JSON.parse(atob(session));
      console.log("Decoded session", decoded);
      userContext
        .login({
          id: decoded.id,
          email: decoded.email,
          username: decoded.username,
          token: decoded.access_token,
          wallet: decoded.wallet,
        })
        .then(() => {
          const play = url.searchParams.get("play");
          localStorage.setItem("logged", true.toString());
          if (play && play !== "") {
            setSocialLoginRequestStatus("success");
            setTimeout(() => {
              localStorage.setItem("play", play);
              history.push(Routes.USER_ITINERARIES);
            }, 500);
          } else {
            setSocialLoginRequestStatus("success");
            history.push(Routes.CITY_SELECTION);
          }
        });
    }
  }, []);

  const showToast = (message: string) => {
    present({
      message: message,
      duration: 2000,
      position: "top",
      color: "primary",
      cssClass: "ion-text-center",
    });
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="end">
            {/* <IonButton
              routerLink={Routes.LOGIN}
              color="tertiary"
              className={`${styles.loginBtn} secondary-font ion-text-uppercase`}
            >
              {t("access.login")}
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={`${styles.accessContainer} ion-padding`}>
          {socialLoginRequestStatus === "pending" ? (
            <IonSpinner className={styles.topMargin} />
          ) : (
            <div style={{ textAlign: "center" }}>
              <IonImg
                src="assets/logo.svg"
                alt="Zonzo logo"
                className={styles.logo}
                style={{ textAlign: "center", display: "inline-block" }}
              ></IonImg>
              <h1 style={{ textAlign: "center" }}>{t("access.welcome")}</h1>
              <a href={"https://api.zonzoapp.com/login?origin=" + origin}>
                <MyButtonWithBorder
                  className={`${styles.topMargin} ion-text-uppercase button-full-width`}
                  size="large"
                  color="secondary"
                >
                  {t("access.login")}
                </MyButtonWithBorder>
                <br></br>
                <MyButtonWithBorder
                  className={`${styles.topMargin} ion-text-uppercase button-full-width`}
                  size="large"
                  routerLink={Routes.SIGNUP}
                  color="secondary"
                >
                  {t("access.register")}
                </MyButtonWithBorder>
                <IonButton
                  routerLink={Routes.RECOVER_PASSWORD}
                  color="tertiary"
                  style={{ marginTop: "20px" }}
                  className={`${styles.registerBtn} secondary-font ion-text-uppercase`}
                >
                  {t("access.recoverPassword")}
                </IonButton>
              </a>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AccessPage;
